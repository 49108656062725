import Vue from "vue";
import LoginDialog from "./loginDialog.vue";

// export function Login() {
//   let loginComponent = Vue.extend(loginDialog);
//   let instance = new loginComponent().$mount();
//   document.body.appendChild(instance.$el);
// }
const loginDialog = new Vue(LoginDialog) 
loginDialog.$mount(document.createElement('div'))
document.body.appendChild(loginDialog.$el)

Vue.prototype.$loginDialog = loginDialog

export default loginDialog

