<template>
  <div id="app">
    <Headers v-show="showTop" @goLogin="goLogin" :is_login="is_login" :user_desc="user_desc" @loginOut="loginOut">
    </Headers>
    <router-view></router-view>
    <Footers v-show="showTop"></Footers>
    <rightMenu v-show="showTop"></rightMenu>
    <!-- <Login :showL="showLogin" @closeLR="closeLogin" @Login="Login"></Login> -->
  </div>
</template>

<script>
  import Headers from './components/header';
  import Footers from './components/footer';
  import rightMenu from './components/right-menu.vue';
  import {
        getSysConfig,
    } from '@/api/index.js'
  export default {
    name: "App",
    data() {
      return {
        showLogin: false,
        showTop: true,
        active: 0,
        is_login: false,
        user_desc: {},
        sysConfig:{}
      }
    },
    methods: {
      goLogin() {
        this.showLogin = true;
      },
      Login(data) {

      },
      loginOut() {
        this.$util.removeStorage();
        // this.$util.reload();
        window.location.reload();
      },
      closeLogin() {
        this.showLogin = false;
      },
      bodyScale() {
        if (window.innerWidth > 1000) return false;
        let devicewidth = window.innerWidth //获取当前分辨率下的可是区域宽度
        let scale = devicewidth / 1920 // 分母——设计稿的尺寸
        document.body.style.zoom = scale //放大缩小相应倍数
      },
      async getConfig(){
        await getSysConfig().then(r=>{
          this.sysConfig = r;
        })
      },
    },
    mounted() {
      if (this.$util.getStorage('user-info')) {
        this.is_login = true;
      } else {
        this.is_login = false;
      }
      // console.log(window.innerWidth)
      this.bodyScale();
      window.onresize = () => {
        return (() => {
          this.bodyScale();
        })()
      }

    },
    async created() {
      await this.getConfig();
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = this.sysConfig.store_icon;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title = this.sysConfig.store_title||'';
    },
    components: {
      Headers: Headers,
      Footers: Footers,
      rightMenu: rightMenu,
    },
    watch: {
      $route(to, from) {
        if (to.meta.hideTop) {
          this.showTop = false;
        } else {
          this.showTop = true;
        }

      },
    }

  };
</script>

<style lang="less">
  @import'https://at.alicdn.com/t/c/font_3674802_fwyag6ahz0m.css';
  // @font-face {
  //     font-family: 'Alibaba-PuHuiTi-Regular';
  //     src: url(../font/Alibaba-PuHuiTi-Regular.otf);
  // }

  *,
  :after,
  :before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    // font-family: 'Microsoft YaHei';
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #999;
  }

  ul,
  li {
    list-style: none;
  }

  a {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
  }

  img {
    max-width: 100%;
    display: block;
    border: none;
  }
  html{
    -webkit-text-size-adjust: auto;
  }

  body {
    background-color: #fafafa;
    overflow-y: overlay;
  }

  .zw {
    height: 64px;
  }

  header {
    min-width: 1440px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: fixed;
    height: 64px;
    background-color: #040000;
    padding: 0 64px;
    z-index: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;

    .logo {
      cursor: pointer;
      width: 100px;
      height: 35px;
      margin-right: 52px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .nav_container {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-orient: horizontal;
      -moz-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;

      .nav_link {
        position: relative;
        font-size: 16px;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        line-height: 64px;
        height: 64px;
        margin-left: 24px;
        letter-spacing: 2px;

        .menu {
          position: absolute;
          width: 1086px;
          // height: 428px;
          padding-top: 32px;
          top: 100%;
          left: -173px;
          // display: none;
          background-color: #fff;
          padding: 0 40px;
          border-radius: 10px;
          box-shadow: 0 0 10px hsl(0deg 0% 40% / 20%);
          background-color: hsla(0, 0%, 100%, .97);
          font-size: 14px;
          color: #666;
          z-index: 6;
          visibility: hidden;
          opacity: 0;
          transition: top .3s, opacity .2s, visibility .2s;
          overflow: hidden;

          .container {
            display: flex;
            width: 100%;
            padding: 24px 0 40px;

            // ul{
            //     margin-right: 40px;
            //     li{
            //         margin-bottom: 13px;
            //         font-size: 14px;
            //         line-height: 1;
            //         a{
            //             color: #555;
            //             font-size: 14px;
            //             line-height: 1.5;
            //         }
            //     }
            //     li:first-child{
            //         margin-bottom: 15px;
            //         a{
            //             display: inline-block;
            //             color: #262626;
            //             font-weight: bold;
            //             font-size: 16px;
            //             min-width: 70px;
            //         }
            //     }
            // }
            .con-list {
              line-height: 26px;

              .con-list-item {
                display: flex;
                flex-wrap: wrap;
                font-size: 12px;

                h5 {
                  position: relative;
                  min-width: 84px;
                  height: 28px;
                  margin: 0;
                  padding-right: 20px;
                  font-weight: 900;
                  color: #333;
                  line-height: 1;
                  text-align: right;
                  font-size: 14px;

                  i {
                    position: absolute;
                    top: 0px;
                    right: 0;
                  }
                }

                .item-list {
                  flex: 1;
                  padding-left: 12px;
                  white-space: normal;
                  font-size: 0;

                  li {
                    display: inline-block;
                    min-width: 70px;
                    height: 14px;
                    line-height: 1;
                    padding-left: 8px;
                    padding-right: 8px;
                    font-size: 14px;
                    white-space: nowrap;
                    border-right: 1px solid #aaa;

                    a {
                      color: #666;

                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }

          .drop-tabs {
            width: 100%;
            display: flex;
            margin-bottom: 20px;

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              height: 64px;
              cursor: pointer;
              border-bottom: 1px solid #eee;

              h4 {
                color: #333;
                font-size: 18px;
                font-weight: 400;
              }
            }

            .drop-tabs-active {
              h4 {
                color: #d12424;
              }
            }
          }
        }

        .more-menu {
          left: 50%;
          padding: 12px 0;
          transform: translateX(-50%);
          position: absolute;
          top: 100%;
          min-width: 120px;
          border-radius: 5px;
          box-shadow: 0 0 15px rgb(0 0 0 / 50%);
          line-height: 36px;
          background-color: hsla(0, 0%, 100%, .95);
          color: #666;
          text-align: center;
          z-index: 6;
          overflow: hidden;
          visibility: hidden;
          opacity: 0;
          transition: opacity .2s, top .2s;

          li {
            transition: background-color .2s;

            a {
              display: inline-block;
              width: 100%;
              color: #333;
            }

            &:hover {
              a {
                background-color: #e80000;
                color: #fff;
              }
            }
          }
        }

        &:hover {
          .menu {
            opacity: 1;
            visibility: visible;
          }

          .more-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    .nav_other {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;

      .personalButton {
        margin-right: 32px;

        &>div {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -moz-box-orient: horizontal;
          -moz-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-size: 16px;
          color: #fff;
          -webkit-transition: -webkit-transform 0.2s;
          transition: -webkit-transform 0.2s;
          transition: transform 0.2s;
          transition: transform 0.2s, -webkit-transform 0.2s;
          height: 34px;
          cursor: pointer;

          img {
            width: 20px;
            aspect-ratio: auto 16 / 13;
            height: 20px;
            margin-right: 4px;
          }
        }
      }

      .nav_login {
        width: 70px;
        line-height: 34px;

        a {
          font-size: 16px;
          letter-spacing: 2px;
          color: #fff;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
        }

        span {
          margin: 0 10px;
          color: #fff;
          font-size: 16px;
        }
      }

      .user_info {
        position: relative;
        height: 64px;
        display: flex;
        align-items: center;

        &:hover {
          .info_box {
            display: block;
          }
        }

        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          cursor: pointer;
          overflow: hidden;
        }

        .info_box {
          position: absolute;
          top: 100%;
          right: 0;
          width: 250px;
          // height: 100px;
          background-color: #fff;
          box-shadow: 0 4px 4px rgb(0 0 0 / 50%);
          border-radius: 3px;
          text-align: left;
          display: none;

          .user {
            display: flex;
            padding: 10px;
            align-items: center;

            .avatar {
              width: 44px;
              height: 44px;
              margin-right: 6px;
              border-radius: 50%;
              overflow: hidden;
            }

            .info-dec {
              height: 44px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex: 1;

              .vip {
                font-size: 12px;
                display: flex;
                justify-content: space-between;

                a {
                  color: #e80000;
                  text-decoration: underline;
                }
              }
            }
          }

          .number {
            padding: 10px;
            background-color: #f5f5f5;
            display: flex;
            align-items: center;
            font-size: 13px;

            &>div {
              width: 100%;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
            }
          }

          .menu {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px 0;

            &>a {
              width: 50%;
              color: #999;
              height: 34px;
              line-height: 34px;
              text-align: center;

              .iconfont {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 8px;
                font-size: 20px;
                vertical-align: -2px;
              }
            }
          }

          .exit {
            a {
              height: 44px;
              display: block;
              line-height: 44px;
              text-align: center;
              width: 100%;
              color: #999;
              background-color: #f6f6f6;
            }
          }
        }
      }
    }
  }

  .w1703 {
    width: 1800px;
    margin: 0 auto;
  }

  .footer-wrap {
    width: 100%;
    min-width: 1200px;
    background-color: #333;

    .footer-content {
      display: flex;
      width: 1800px;
      height: 220px;
      margin: 0 auto;
      padding-top: 30px;
      align-items: flex-start;
      // border-bottom: 1px solid #4b4b4b;
      font-size: 12px;
      color: #999;

      .l-quick-link {
        ul {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          li {
            margin-right: 40px;

            a {
              display: block;
              min-height: 26px;
              line-height: 26px;
              color: #999;
              cursor: pointer;
            }
          }

          li:first-child {
            width: 66px;
            height: 26px;
            line-height: 26px;
            margin-right: 24px;
            border-radius: 4px;
            background-color: #222;
            text-align: center;
            color: #fff;
          }
        }

        .mzsm {
          align-items: flex-start;

          li {
            white-space: normal;
          }

          li:last-child {
            // position: absolute;
            left: 90px;
          }
        }
      }

      .c-qrcode {
        display: flex;
        align-items: center;
        flex: 1;
        line-height: 1;
        justify-content: center;
        text-align: center;

        li {
          margin-right: 40px;
          font-size: 14px;

          .img {
            background-color: #fff;
            padding: 10px;
            margin-bottom: 14px;
          }

          img {
            display: block;
            width: 122px;
            max-height: 122px;
            border-radius: 4px;
            overflow: hidden;
          }
        }
      }

      .r-contact {
        line-height: 1;
        margin-left: 12px;
        font-size: 14px;
        white-space: nowrap;

        b {
          font-size: 30px;
          color: #f0f0f0;
        }

        li:last-child {
          margin-top: 30px;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 34px;
            min-height: 34px;
            height: 34px;
            line-height: 34px;
            // margin-right: 6px;
            padding: 0 8px;
            border-radius: 4px;
            text-align: center;
            background-color: #222;
            color: #999;
            width: 100%;
          }

          .img {
            display: inline-block;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .footer-img {
      padding-top: 30px;
      text-align: center;

      a {
        transition: color .2s, background-color .2s;
      }

      &>a {
        margin: 0 10px;
      }

      img {
        max-width: 84px;
      }
    }

    .copyright {
      line-height: 40px;
      font-size: 12px;
      color: #999;
      text-align: center;
    }
  }

  .phone-head {
    display: none;
  }

  #right-menu {
    position: fixed;
    right: 6px;
    bottom: 20%;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px rgb(85 85 85 / 15%);
    border-radius: 8px;
    width: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    .menu-item {
      position: relative;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      border-radius: 4px;
      cursor: pointer;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      padding-top: 10px;
      padding-bottom: 10px;

      .iconfont {
        width: 26px;
        height: 26px;
        font-size: 26px;
        color: #d12424;

      }

      .bold {
        font-weight: 700;
      }

      span {
        margin-top: 0.03rem;
        color: #666;
        font-size: 12px;
      }

      .left-box {
        position: absolute;
        right: 100%;
        top: 0;
        border-radius: 5px;
        display: none;
      }

      &:hover {
        background-color: #e80000;

        .iconfont,
        span {
          color: #fff;
        }

        .left-box {
          display: flex;
        }
      }
    }
  }

  @media only screen and (max-width: 1800px) {
    header .logo {
      margin-right: 0;
    }

    .w1703 {
      width: 1500px;
    }

    .footer-wrap {
      .footer-content {
        width: 1500px;
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    header {
      padding: 0 32px;

      .nav_container .nav_link {
        font-size: 15px;
        margin-left: 22px;
      }

      .nav_other {
        font-size: 15px;

        .personalButton {
          margin-right: 20px;
        }
      }
    }
  }

  @media only screen and (max-width: 1500px) {

    .w1703 {
      width: 1200px;
    }

    .footer-wrap {
      .footer-content {
        width: 1200px;

        .l-quick-link ul {
          position: relative;
        }

        .l-quick-link ul li {
          margin-right: 20px;
          // white-space: nowrap;
        }

        .l-quick-link ul:first-child li:last-child {
          display: none;
        }

        .c-qrcode li {
          margin-right: 20px;

          img {
            width: 100px;
            max-height: 100px;
          }
        }

        .l-quick-link ul:last-child {
          align-items: flex-start;
        }

        .l-quick-link ul:last-child li:last-child {
          position: absolute;
          left: 90px;
        }

        .l-quick-link {
          .mzsm {
            align-items: flex-start;

            li {
              white-space: normal;
            }

            li:last-child {
              position: absolute;
              left: 90px;
            }
          }
        }

      }
    }
  }

  // @media only screen and (max-width: 1000px) {

  //   header {
  //     display: none;
  //   }

  //   .phone-head {
  //     display: block;
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     // padding: 0 20px;
  //     background-color: #040000;
  //     height: 64px;
  //     display: flex;
  //     align-items: center;
  //     z-index: 999;
  //     justify-content: space-between;

  //     .logo {
  //       padding-left: 20px;

  //       img {
  //         width: 100px;
  //         max-width: 100%;
  //       }
  //     }

  //     .menu-box {
  //       display: flex;
  //       align-items: center;
  //       padding-right: 20px;

  //       img {
  //         width: 30px;
  //       }

  //       .menu {
  //         margin-left: 20px;
  //       }
  //     }
  //   }

  //   .w1703 {
  //     width: 100%;
  //     padding: 0 10px;
  //   }

  //   .footer-wrap {
  //     min-width: 0;
  //     width: 100%;

  //     .footer-content {
  //       width: 100%;
  //       flex-wrap: wrap;
  //       height: auto;
  //       padding-bottom: 30px;
  //       justify-content: center;

  //       .l-quick-link {
  //         display: none;
  //       }

  //       .c-qrcode {
  //         width: 100%;
  //         margin-bottom: 20px;
  //         flex: none;

  //         li {
  //           margin-right: 0;
  //         }

  //         li:last-child {
  //           display: none;
  //         }
  //       }
  //     }
  //   }

  //   #right-menu {
  //     display: none;
  //   }
  // }

  .homeBanner {
    .swiper-slide {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        pointer-events:none;
        z-index: 3;
      }
    }
  }

  #mainSwiper {
    .swiper-pagination-bullet {
      height: 6px;
      width: 24px;
      background: #fff;
      -webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
      box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
      opacity: 0.41;
      border-radius: 3px;
      font-size: 0;
      border: 0;
      color: transparent;
      display: inline-block;
      transition: all .5s;
      outline: none;
      cursor: pointer;
    }

    .swiper-pagination-bullet-active {
      width: 44px;
      opacity: 0.81;
    }

    .swiper-slide {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

  }

  #lessonBanner {
    .swiper-pagination {
      position: absolute;
      width: 100%;
      height: 36px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .swiper-pagination-bullet {
        position: relative;
        display: inline-block;
        margin: 0 2px;
        padding: 0;
        text-align: center;
        vertical-align: top;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 100%;
        font-size: 14px;
        background-color: transparent;
        font-weight: 400;
        color: rgba(255, 255, 255);
        line-height: 36px;
        cursor: pointer;
        opacity: .6;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
        font-weight: 500;
      }
    }
  }

  .el-input__prefix {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    left: 10px;

    .iconfont {
      font-size: 20px;
    }
  }

  .ipt-box .el-input--prefix .el-input__inner {
    padding-left: 40px;
  }

  .ipt-box .el-input__inner:focus {
    border-color: #DCDFE6;
  }

  #app .w1703 .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    li {
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-radius: 8px;
    }

    .el-pager li:not(.disabled).active {
      font-weight: 400;
      font-size: 14px;
      background: #333;
      color: #fff;
    }

    .pagination-item {
      min-width: 32px;

      text-align: center;
      list-style: none;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      outline: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: none;
      width: 40px;
      height: 40px;
      background: #fafafa;
      border-radius: 8px;
      margin: 0 6px;

      a {
        color: #333;
      }
    }

    .pagination-item-active {
      font-weight: 400;
      font-size: 14px;
      background: #333;

      a {
        color: #fff;
      }
    }

    .pagination-prev {
      width: 78px;
      height: 40px;
      background: #fafafa;
      border-radius: 8px;

      a {
        color: #333;
      }

      span:nth-child(1) {
        display: inline-block;
        background: url(https://cdn.code.znzmo.com/sucai/img/images/pagepreIcon.png) no-repeat center;
        background-size: cover;
        width: 14px;
        height: 15px;
        vertical-align: middle;
      }
    }

    .pagination-next {
      width: 78px;
      height: 40px;
      background: #fafafa;
      border-radius: 8px;

      a {
        color: #333;
      }

      span:nth-child(2) {
        display: inline-block;
        background: url(https://cdn.code.znzmo.com/sucai/img/images/pagenextIcon.png) no-repeat center;
        background-size: cover;
        width: 14px;
        height: 15px;
        vertical-align: middle;
      }
    }

    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .btn-next {
      width: 78px;
      height: 40px;
      background: #fafafa;
      border-radius: 8px;
      color: #333;

      span {
        font-size: 16px;
        font-weight: 500;
      }

      // a {
      //     color: #333;
      // }
    }
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #333;
  }

  .model-mask {
    .el-button:focus {
      background: #f1f5f8;
      color: #606266;
      border-color: #DCDFE6;
    }
  }

  .detail-carousel .el-image__preview {
    cursor: zoom-in;
  }

  .xgplayer-skin-default video {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .upload-form {
    .disabled .el-upload--picture-card {
      display: none !important;
    }

    .disabled .el-button--success.is-plain {
      display: none !important;
    }

    .el-button--primary {
      background-color: #e80000;
      border-color: #e80000;
    }

    .el-button--primary:hover {
      background-color: #e80000;
      border-color: #e80000;
    }

    .el-button--primary:focus {
      background-color: #e80000;
      border-color: #e80000;
    }

    .el-radio__input.is-checked+.el-radio__label {
      color: #606266;
    }

    .el-radio__input.is-checked .el-radio__inner {
      background-color: #e80000;
      border-color: #e80000;
    }
  }

  // 给文本内容加高度，滚动条
  .quill-editor .ql-container {
    min-height: 220px;
  }

  .ql-container {
    min-height: 230px;
  }

  body {
    .ql-snow .ql-tooltip [data-mode="link"]::before {
      content: "请输入链接地址:";
      left: 0;
    }

    .ql-snow .ql-tooltip.ql-editing {
      left: 0 !important;
    }

    .ql-snow .ql-tooltip {
      left: 0 !important;
    }

    .ql-snow .ql-editor {
      max-height: 500px;
    }

    .preview .ql-snow .ql-editor {
      max-height: unset;
    }

    .detail .ql-snow .ql-editor {
      max-height: unset;
    }

    .quill-editor.ql-editor {
      padding: 0;
    }

    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
      border-right: 0px;
      content: "保存";
      padding-right: 0px;
    }

    .ql-snow .ql-tooltip[data-mode="video"]::before {
      content: "请输入视频地址:";
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
      content: "14px" !important;
      font-size: 14px;
    }

    .ql-editor .ql-size-12px {
      font-size: 12px;
    }

    .ql-editor .ql-size-14px {
      font-size: 14px;
    }

    .ql-editor .ql-size-16px {
      font-size: 16px;
    }

    .ql-editor .ql-size-18px {
      font-size: 18px;
    }

    .ql-editor .ql-size-20px {
      font-size: 20px;
    }

    .ql-editor .ql-size-32px {
      font-size: 32px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
      content: "10px" !important;
      font-size: 10px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
      content: "12px" !important;
      font-size: 12px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
      content: "16px" !important;
      font-size: 16px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
      content: "18px" !important;
      font-size: 18px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
      content: "20px" !important;
      font-size: 20px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
      content: "30px" !important;
      font-size: 30px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
      content: "30px" !important;
      font-size: 30px;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
      content: "32px" !important;
      font-size: 32px;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
      content: "文本" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
      content: "标题1" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
      content: "标题2" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
      content: "标题3" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
      content: "标题4" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
      content: "标题5" !important;
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
      content: "标题6" !important;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
      content: "标准字体" !important;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
      content: "衬线字体" !important;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
      content: "等宽字体" !important;
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimSun"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimSun"]::before {
      content: "宋体" !important;
      font-family: "SimSun";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
      content: "黑体" !important;
      font-family: "SimHei";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Microsoft-YaHei"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Microsoft-YaHei"]::before {
      content: "微软雅黑" !important;
      font-family: "Microsoft YaHei";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
      content: "楷体" !important;
      font-family: "KaiTi";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
      content: "仿宋" !important;
      font-family: "FangSong";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
      content: "Arial" !important;
      font-family: "Arial";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Times-New-Roman"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Times-New-Roman"]::before {
      content: "Times New Roman" !important;
      font-family: "Times New Roman";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
      content: "sans-serif" !important;
      font-family: "sans-serif";
    }

    .ql-font-SimSun {
      font-family: "SimSun";
    }

    .ql-font-SimHei {
      font-family: "SimHei";
    }

    .ql-font-Microsoft-YaHei {
      font-family: "Microsoft YaHei";
    }

    .ql-font-KaiTi {
      font-family: "KaiTi";
    }

    .ql-font-FangSong {
      font-family: "FangSong";
    }

    .ql-font-Arial {
      font-family: "Arial";
    }

    .ql-font-Times-New-Roman {
      font-family: "Times New Roman";
    }

    .ql-font-sans-serif {
      font-family: "sans-serif";
    }

    .ql-snow.ql-toolbar .ql-formats .ql-revoke {
      // background-image: url("../../../../assets/images/icons8-rotate-left-18.png");
      width: 20px;
      height: 20px;
      filter: grayscale(100%);
      opacity: 1;
    }

    .ql-snow.ql-toolbar .ql-formats .ql-revoke:hover {
      // background-image: url("../../../../assets/images/icons8-rotate-left-18.png");
      width: 20px;
      height: 20px;
      filter: none;
      opacity: 0.9;
    }

    .ql-snow .ql-editor img {
      display: inline-block;
    }

    // img {
    //   filter: grayscale(100%);
    //   opacity: 1;
    // }

    // img:hover {
    //   filter: none;
    //   opacity: 0.9;
    // }

    /*加上height和滚动属性就可以，滚动条样式是系统默认样式，可能不同*/
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
      border-color: #ccc;
      height: 125px;
      overflow: auto;
    }
  }
</style>