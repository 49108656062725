//配置路由的地方
import Vue from "vue";
import VueRouter from "vue-router";

//先把VueRouter院校对象的push，先保存一份
const originalPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;
//重写push|replace
//第一个参数：告诉原来的push方法，你往哪里跳转（传递哪些参数）
//第二个参数：成功的回调
//第三个参数：失败的回调
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //call || apply 区别：
    //相同点，都可以调用函数一次，都可以篡改函数的上下文

    //不同点：call与apply传递参数：call传递参数用逗号隔开，apply方法执行，传递数组
    originalPush.call(this, location, resolve, reject);
  } else {
    originalPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    //call || apply 区别：
    //相同点，都可以调用函数一次，都可以篡改函数的上下文

    //不同点：call与apply传递参数：call传递参数用逗号隔开，apply方法执行，传递数组
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

// VueRouter.prototype.push = function push(location, onResolve, onReject) {
// if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
// return originalPush.call(this, location).catch(err => err)
// }

//使用插件
Vue.use(VueRouter);
//引入路由组件
/**
 * !直接引入
 *  import Home from '@/pages/Home';
    import Search from '@/pages/Search';
    import Login from '@/pages/Login';
    import Register from '@/pages/Register';


    !按需引入(懒加载)
    const Home = () => import('../components/Header');
    const Search = () => import('../components/Search');
    const Login = () => import('../components/Login');
    const Register = () => import('../components/Register');
 * 
 */

// const Home = () => import('../pages/Home/home.vue');
// const Search = () => import('../pages/Search/search.vue');
// const Login = () => import('../pages/Login/login.vue');
// const Register = () => import('../pages/Register/register.vue');
//配置路由
export default new VueRouter({
  //配置路由
  mode: "hash",
  // base: process.env.NODE_ENV === "production" ? "/h5" : "/",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/pages/Home/home"),
      meta: { title:'首页' },
    },
    {
      path: "/su",
      name: "su",
      component: () => import("@/pages/Home/suModel"),
      meta:{title:'SU模型'}
    },
    {
      path: "/lesson",
      name: "lesson",
      component: () => import("@/pages/Home/lesson"),
      meta:{title:'精品课程'}
    },
    {
      path: "/3D",
      name: "3D",
      component: () => import("@/pages/Home/3D"),
      meta:{title:'3D模型'}
    },
    {
      path: "/maps",
      name: "maps",
      component: () => import("@/pages/Home/maps"),
      meta:{title:'贴图/灯光'}
    },
    {
      path: "/bqdj",
      name: "bqdj",
      component: () => import("@/pages/Home/bqdj"),
      meta:{title:'版权登记'}
    },
    {
      path: "/list",
      name: "list",
      component: () => import("@/pages/Home/list"),
      
    },
    {
      path: "/lessonInfo",
      name: "lessonInfo",
      component: () => import("@/pages/Home/lesson-info"),
    },
    {
      path: "/lessonDetail",
      name: "lessonDetail",
      component: () => import("@/pages/Home/lesson-detail"),
      meta:{hideTop:true},
    },
    {
      path: "/listDetail",
      name: "listDetail",
      component: () => import("@/pages/Home/list-detail"),
    },
    {
      path: "/xieyi",
      name: "xieyi",
      component: () => import("@/pages/Home/xieyi"),
    },
    {
      path: "/eduUpload",
      name: "eduUpload",
      component: () => import("@/pages/Home/eduUpload"),
      meta:{title:'上传课程'}
    },
    {
      path: "/eduCatalog",
      name: "eduCatalog",
      component: () => import("@/pages/Home/eduCatalog"),
      meta:{title:'目录管理'}
    },
    {
      path: "/member",
      name: "member",
      component: () => import("@/pages/User/member"),
      redirect: "/member/index", //页面默认加载的路由
      children: [
        {
          path: "/member/index",
          name: "index",
          component: () =>
            import("@/pages/User/childPages/index.vue"),
          meta:{title:'个人首页'}
        },
        {
          path: "/member/vip",
          name: "vip",
          component: () =>
            import("@/pages/User/childPages/vip.vue"),
          meta:{title:'vip充值'}
        },
        {
          path: "/member/recharge",
          name: "recharge",
          component: () =>
            import("@/pages/User/childPages/recharge.vue"),
          
        },
        {
          path: "/member/collect",
          name: "collect",
          component: () =>
            import("@/pages/User/childPages/collect.vue"),
            meta:{title:'我的收藏'}
        },
        {
          path: "/member/order",
          name: "order",
          component: () =>
            import("@/pages/User/childPages/order.vue"),
            meta:{title:'我的订单'}
        },
        {
          path: "/member/record",
          name: "record",
          component: () =>
            import("@/pages/User/childPages/record.vue"),
            meta:{title:'作品列表'}
        },
        {
          path: "/member/info",
          name: "info",
          component: () =>
            import("@/pages/User/childPages/info.vue"),
            meta:{title:'个人信息'}
        },
        {
          path: "/member/upload",
          name: "upload",
          component: () =>
            import("@/pages/User/childPages/upload.vue"),
            meta:{title:'我的上传'}
        },
        {
          path: "/member/withdrawal",
          name: "withdrawal",
          component: () =>
            import("@/pages/User/childPages/withdrawal.vue"),
            meta:{title:'收益提现'}
        },
        {
          path: "/member/lessonControl",
          name: "lessonControl",
          component: () =>
            import("@/pages/User/childPages/lessonControl.vue"),
            meta:{title:'课程管理'}
        },
      ],
    },
    // {
    //   path: "/search/:keyword?",
    //   name: "search",
    //   component: Search,
    //   //路由组件能不能传递props数据？
    //   // 布尔值写法：只能传递params单数
    //   // props: true,
    //   //对象写法:额外的给路由组件传递一些props
    //   // props:{a:1,b:2},
    //   //函数写法：可以把params参数、query参数，通过props传递给路由组件
    //   // props: ($route) => {
    //   //   return {
    //   //     keyword: $route.params.keyword,
    //   //     k: $route.query.k,
    //   //   };
    //   // },
    // },

    // {
    //   path: "/search",
    //   name: "search",
    //   component: () => import("@/pages/Home/search")
    // },

    // {
    //   path: "/register",
    //   name: "register",
    //   component: Register,
    //   meta: {
    //     hide: true,
    //   },
    // },
    //重定向,
    {
      path: "*",
      redirect: "/home",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
