//当前这个模块：API进行统一管理
import requests from "./request";
//用户登录接口  /api/common/login （get请求，参数：{phone: String, code: String}）
export const getSMS = (params) =>
  requests({ url: "/common/sendSms", method: "get", data: params });
export const getLoginByPhoneCode = (params) =>
  requests({ url: "/common/login", method: "get", data: params });
//获取分类列表 (参数：栏目ID，为空或不传为获取一级栏目或分类)
export const getMainCategoryList = (params) =>
  requests({ url: "/category/getCategoryList", method: "get", data: params });
//获取分类列表 参数 tpl getCategoryListByTpl
export const getMainCategoryListByTpl = (params) =>
  requests({ url: "/category/getCategoryListByTpl", method: "get", data: params });
//个人中心获取用户信息
export const getUserInfo = () =>
  requests({ url: "/user/getUserInfo", method: "get", token: true });
//首页获取内容分类接口
export const getIndexCateInfo = () =>
  requests({ url: "/common/getHomeOne", method: "get" });
//首页-精品课程列表
export const getHomeVideoList = () =>
  requests({ url: "/common/getHomeVideoList", method: "get" })
//首页获取轮播图
export const getIndexBanner = () =>
  requests({ url: "/common/getBannerList", method: "get" });
//获取素材详情
export const getMeatDetailById = (params) =>
  requests({
    url: "/product/detail",
    method: "get",
    token: true,
    data: params,
  });
//判断素材能否下载
export const getMeatLocationById = (params) =>
  requests({
    url: "/product/beforeDownload",
    method: "get",
    token: true,
    data: params,
  });
//获取素材下载地址
export const getMeatRealLocationById = (params) =>
  requests({
    url: "/product/download",
    method: "get",
    token: true,
    data: params,
  });
//获取素材列表
export const getMeatListByClassCode = (params) =>
  requests({ url: "/category/index", method: "get", data: params });
//获取课程列表
export const getVideoListByClassCode = (params) =>
  requests({ url: "/video/getVideoList", method: "get", data: params });
//获取订单支付状态
export const getOrderPayStatus = (params) =>
  requests({ url: "/common/checkOrderStatus", method: "get", data: params });
//收藏素材
export const getCollectionMeta = (params) =>
  requests({
    url: "/product/collect",
    method: "get",
    token: true,
    data: params,
  });
//取消收藏素材
export const getCollectionMetaDel = (params) =>
  requests({
    url: "/user/deleteCollect",
    method: "get",
    token: true,
    data: params,
  });
//获取vip套餐
export const getVIPPackage = () =>
  requests({ url: "/vip/getMemberLevelList", method: "get" });
//购买vip套餐
export const getBuyVIPPackage = (params) =>
  requests({ url: "/vip/buy", method: "get", token: true, data: params });
//获取用户收藏列表
export const getUserCollect = (params) =>
  requests({
    url: "/user/collectList",
    method: "get",
    token: true,
    data: params,
  });
//获取用户订单列表
export const getUserOrderList = (params) =>
  requests({ url: "/user/orderList", method: "get", token: true, data: params });
//用户申请成为老师
export const getUserApplyTeacher = () =>
  requests({ url: "/user/applyTeacher", method: "get", token: true });
//获取付费类型
export const getChargeList = () =>
  requests({ url: "/common/getChargeList", method: "get", });
//上传和修改素材
export const postUploadProduct = (params) =>
  requests({ url: "/user/uploadProduct", method: "post", token: true, data: params });
//获取用户素材列表
export const getUserProductList = (params) =>
  requests({ url: "/user/getProductList", method: "get", token: true, data: params });
//获取作品详情（编辑时）
export const getUserProductDetail = (params) =>
  requests({ url: "/user/productDetail", method: "get", token: true, data: params });
//删除作品
export const getProductDel = (params) =>
  requests({ url: "/user/deleteProduct", method: "get", token: true, data: params });
//获取课程详情
export const getLessonInfo = (params) =>
  requests({ url: "/video/detail", method: "get", token: true, data: params });
//购买课程
export const buyLesson = (params) =>
  requests({ url: "/video/buy", method: "get", token: true, data: params });
//观看视频前确认
export const getBeforePlayer = (params) =>
  requests({ url: "/video/buy", method: "get", token: true, data: params });
//课程收藏 
export const getVideoCollect = (params) =>
  requests({ url: "/video/collect", method: "get", token: true, data: params });
//上传和修改课程
export const postUploadLesson = (params) =>
  requests({ url: "/video/editUserVideo", method: "post", token: true, data: params });
//获取用户课程列表 getUserVideoList
export const getUserVideoList = (params) =>
  requests({ url: "/video/getUserVideoList", method: "get", token: true, data: params });
//获取课程详情（编辑时）
export const getUserVideoDetail = (params) =>
  requests({ url: "/video/getUserVideoDetail", method: "get", token: true, data: params });
//删除课程
export const deleteUserVideo = (params) =>
  requests({ url: "/video/deleteUserVideo", method: "get", token: true, data: params });
//获取课程目录列表 
export const getUserSingleVideoDirList = (params) =>
  requests({ url: "/video/getUserSingleVideoDirList", method: "get", token: true, data: params });
//获取课程目录下的视频列表 
export const getUserVideoDirNodesList = (params) =>
  requests({ url: "/video/getUserVideoDirNodesList", method: "get", token: true, data: params });
//删除目录 
export const deleteVideoDir = (params) =>
  requests({ url: "/video/deleteVideoDir", method: "get", token: true, data: params });
//删除视频
export const deleteVideoDirNodes = (params) =>
  requests({ url: "/video/deleteVideoDirNodes", method: "get", token: true, data: params });
//添加或编辑目录 
export const editVideoDir = (params) =>
  requests({ url: "/video/editVideoDir", method: "post", token: true, data: params });
//添加或编辑视频
export const editVideoDirNodes = (params) =>
  requests({ url: "/video/editVideoDirNodes", method: "post", token: true, data: params });
//获取七牛云配置 
export const getQiniuConfig = () =>
  requests({ url: "/user/getQiniuConfig", method: "get", token: true});
//获取课程目录和视频列表 
export const getDirsNodes = (params) =>
  requests({ url: "/video/getDirsNodes", method: "get", token: true, data: params });
//获取视频播放地址 
export const getNodesResources = (params) =>
  requests({ url: "/video/getNodesResources", method: "get", token: true, data: params });
//用户提现记录
export const getUserOutRecord = (params) =>
  requests({ url: "/user/cashOutRecord", method: "get", token: true, data: params });
//用户发起提现
export const postUserCashOut = (params) =>
  requests({ url: "/user/cashOut", method: "post", token: true, data: params });
//获取系统配置 
export const getSysConfig = () =>
  requests({ url: "/common/getSysConfig", method: "get" });