//对于axios进行二次封装
import axios from "axios";
import router from "@/router";
import { MessageBox, Message } from "element-ui";
import loginDialog from "@/components/login";

//引入进度条
import nprogress from "nprogress";
//start方法：进度条开始，  done方法：进度条结束
//引入进度条样式
import "nprogress/nprogress.css";

//1：利用axios对象的方法create，去创建一个axios实例
//2：requests就是axios，只不过稍微配置一下
//配置请求
const requests = axios.create({
  //配置对象
  //基础路径，发请求的时候，路径当中会出现api
  baseURL: "/api",
  //代表请求超时的时间10秒
  timeout: 360000,
});
//process.env.NODE_ENV === 'production'?'/h5':'/api'  process.env.NODE_ENV === 'production'?apiUrl.baseUrl:'/api'
//请求拦截器：在发请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
requests.interceptors.request.use((config) => {
  //config:配置对象，对象里面有一个属性很重要，header请求头
  //进度条开始
  nprogress.start();
  //加载动画开始

  //如果为上传图片
  if (config.up_type) {
    config.headers = { "Content-Type": "multipart/form-data" };
  }
  //get请求需要把参数放在 params对象中,否则无法接收
  if (config.method == "get") {
    config.params = config.data;
    // delete config.data
  }
  //携带token
  if (config.token) {
    let user = getToken();
    if (!user || user == null) {
      return config;
    }
    config.headers["user-id"] = user.id;
    config.headers["token"] = user.token;
  }
  // console.log(config)
  return config;
});

//请求完成后
requests.interceptors.response.use(
  (res) => {
    //成功的回调函数：服务器响应数据回来以后，响应拦截器可以检测到，可以做一些事情
    //进度条结束
    nprogress.done();

    //如果返回信息，则显示
    if (res.data.info != "") {
      MessageBox.alert(res.data.info, { confirmButtonText: "确定" });
    }
    if (res.data.code == 1) {
      //成功
      // if (res.data.info != "") {
      //   Message.success(res.data.info)
      // }
      return Promise.resolve(res.data.data);
    } else if (res.data.code == "-1") {
      //未登录
      loginDialog.open();
      // router.push({ path: "/login" });//跳转登录
      // if (res.data.info != "") {
      //   Message.error(res.data.info)
      // }
      return Promise.reject(-1);
    } else {
      //失败
      // if (res.data.info != "") {
      //   Message.error(res.data.info)
      // }
      return Promise.reject(0);
    }
  },
  (err) => {
    //响应失败的回调信息
    // Toast.clear();
    nprogress.done();
    console.log(err);
    if (err && err.stack.indexOf("timeout") > -1) {
      Message.error("请求超时");
    } else {
      Message.error("请求失败");
    }

    return Promise.reject(3);
  }
);
//获取存储的token
function getToken() {
  let val = localStorage.getItem("user-info_");
  if (!val) {
    return val;
  }
  val = JSON.parse(val);
  return val;
}

//对外暴露
export default requests;
