<template>
    <div>
        <div class="zw"></div>
        <header>
            <div class="logo">
                <a href="/">
                    <img src="@/assets/img/logo-1.png" alt="">
                </a>
            </div>
            <nav class="nav_container" v-if="show_menu">
                <router-link to="/" class="nav_link">首页</router-link>
                <div class="nav_link" v-for="(item,index) in list" :key="item.id">
                    <router-link :to="{name:'list',query:{jump_type:item.jump_type,classcode:item.classcode}}">{{item.name}}</router-link>
                    <div class="menu" v-if="item.hasChild" :style="{left:(-103*index)+'px'}">
                        <ul class="drop-tabs"><!--:style="{flex:item2.hasChild?'1':'none'}"  v-if="item2.hasChild"-->
                            <div v-for="(item2,index2) in item.child" :key="item2.id" style="flex:1;">
                                <li :index="index2" :class="active_list[index] == index2?'drop-tabs-active':''"
                                    @mouseenter="hoverMenu(index,index2)">
                                    <i class="icon-cube-2"></i>
                                    <h4>{{item2.name}}</h4>
                                </li>
                            </div>
                        </ul>
                        <div class="container">
                            <div class="con-list" v-for="(item2,index2) in item.child" :key="item2.id"
                                v-show="active_list[index] == index2">
                                <div class="con-list-item" v-for="(citem) in item2.child" :key="citem.id">
                                    <h5>{{citem.name}}<i>></i></h5>
                                    <ul class="item-list">
                                        <li v-for="(pitem) in citem.child" :key="pitem.id">
                                            <router-link :to="{name:'list',query:{jump_type:item.jump_type,classcode:pitem.classcode}}" :title="pitem.name">{{pitem.name}}</router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nav_link">
                    <router-link :to="{name:'xieyi',query:{type:'bqdj'}}">版权登记</router-link>
                </div>
                <div class="nav_link">
                    <a href="javascript:;">更多</a>
                    <div class="more-menu">
                        <li>
                            <router-link :to="{name:'xieyi',query:{type:'ppt'}}">ppt</router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'xieyi',query:{type:'gzs'}}">工作室入驻</router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'xieyi',query:{type:'swhz'}}">商务合作</router-link>
                        </li>
                    </div>
                </div>
            </nav>
            <div class="nav_other" v-if="show_menu">
                <div class="personalButton" @click="Link('upload',{},true)">
                    <div>
                        <img src="@/assets/img/upload.png" alt=""> 上传
                    </div>
                </div>
                <!-- <a href="" class="personalButton">
                    <div>
                        <img src="@/assets/img/cz.png" alt="">充值
                    </div>
                </a> -->
                <div class="personalButton" @click="Link('vip',{},true)">
                    <div>
                        <img src="@/assets/img/vip.png" alt=""> VIP
                    </div>
                </div>
                <div class="nav_login" v-if="!is_login">
                    <a href="javascript:;" @click="Login">登录</a>
                    <!-- <span>|</span> -->
                    <!-- <a href="javascript:;">注册有礼</a> -->
                </div>
                <div class="user_info" v-if="is_login">
                    <div class="avatar">
                        <img :src="user_info_less.headimg" alt="">
                    </div>
                    <div class="info_box">
                        <div class="user">
                            <div class="avatar">
                                <img :src="user_info_less.headimg" alt="">
                            </div>
                            <div class="info-dec">
                                <div class="name">{{user_info_less.nickname}}</div>
                                <div class="vip">
                                    <span>{{user_info_less.vip_name}}</span>
                                    <a @click="Link('vip',{},true)">{{user_info_less.vip_name=='普通用户'?'马上开通':'续费'}}</a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="number">
                            <div>
                                金币:0
                            </div>
                            <div>
                                收益:0
                            </div>
                            <div>
                                作品:0
                            </div>
                        </div> -->
                        <div class="menu">
                            <router-link class="border-top" to="/member/index">
                                <i class="iconfont icon-houtaishangpinguanli"></i>
                                <span>个人中心</span>
                            </router-link>
                            <!-- <router-link to="/member/recharge">
                                <i class="iconfont icon-chongzhi"></i>
                                <span>充值金币</span>
                            </router-link> -->
                            <router-link to="/member/collect" class="border-top">
                                <i class="iconfont icon-jushoucang"></i>
                                <span>我的收藏</span>
                            </router-link>
                            <!-- <router-link to="/member/upload">
                                <i class="iconfont icon-zuopin"></i>
                                <span>我的上传</span>
                            </router-link> -->
                        </div>
                        <div class="exit" @click="loginOut">
                            <a href="javascript:;">退出</a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="phone-head">
            <div class="logo">
                <a href="/">
                    <img src="@/assets/img/logo-1.png" alt="">
                </a>
            </div>
            <div class="menu-box">
                <div class="search">
                    <img src="@/assets/img/search.png" alt="">
                </div>
                <div class="menu">
                    <img src="@/assets/img/menu.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getMainCategoryList
    } from '@/api/index.js'
    export default {
        name: "headers",
        props: {
            show_menu: {
                type: Boolean,
                default: true
            },
            is_login: {
                type: Boolean,
                default: false
            },
            user_desc: {
                type: Object,
                default: {}
            },
            list_su: Array,
        },
        data() {
            return {
                keyword: '',
                user_info_less: {},
                active_list: [],
                list: [],
            }
        },
        methods: {
            Login() {
                this.$loginDialog.open();
            },
            loginOut() {
                this.$emit('loginOut', {})
            },
            hoverMenu(index, index2) {
                this.active_list[index] = index2;
                this.$forceUpdate();
            },
            getCate() {
                getMainCategoryList().then(res => {
                    this.list = res;
                    this.$util.setStorage('menu',res);
                    this.$forceUpdate();
                    res.forEach((item, index) => {
                        this.active_list.push(0);
                        if (item.hasChild) {
                            getMainCategoryList({
                                cate_id: item.id
                            }).then(r => {
                                this.list[index].child = r;
                                this.$forceUpdate();
                                r.forEach((item2, index2) => {
                                    if (item2.hasChild) {
                                        getMainCategoryList({
                                            cate_id: item2.id
                                        }).then(rs => {
                                            this.list[index].child[index2]
                                                .child = rs;
                                            this.$forceUpdate();
                                            rs.forEach((item3, index3) => {
                                                if (item3.hasChild) {
                                                    getMainCategoryList
                                                        ({
                                                            cate_id: item3
                                                                .id
                                                        }).then(rss => {
                                                            this.list[
                                                                    index
                                                                ]
                                                                .child[
                                                                    index2
                                                                ]
                                                                .child[
                                                                    index3
                                                                ]
                                                                .child =
                                                                rss;

                                                            this
                                                                .$forceUpdate();
                                                        })
                                                }
                                            })
                                        })
                                    }
                                })
                            })
                        }
                    })
                    // this.list = list;
                    // this.$forceUpdate();
                    // console.log(list)
                })
            },
            // dgGetList(id){
            //     if(!id) return false;
            //     getMainCategoryList({cate_id:id}).then(r=>{
            //         r.forEach((item,index)=>{
            //             if(item.hasChild){
            //                 this.dgGetList(item.id)
            //             }
            //         })
            //     })
            // },
        },
        mounted() {
            // if(this.$util.getStorage('user_info')){
            //     this.is_login = true;
            // }else{
            //     this.is_login = false;
            // }
            this.getCate();
            this.user_info_less = this.$util.getStorage('user-info') || {};
        },
        watch: {

        }
    };
</script>

<style lang="less" scoped>
</style>