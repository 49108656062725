import request from "@/api/request.js";
export default {
  //跳转  check 是否验证登录
  Link(name, query = {}, check = false, target = false) {
    if (!name) {
      return false;
    }
    // this.$loginDialog.open();
    // return false;
    if (check) {
      if (
        !localStorage.getItem("user-info") ||
        localStorage.getItem("user-info").id <= 0
      ) {
        this.$loginDialog.open();
        return false;
      }
    }
    if (target) {
      let routeUrl = this.$router.resolve({ name: name, query: query });
      window.open(routeUrl.href, "_blank");
    } else {
      this.$router.push({ name: name, query: query });
    }
  },
  //跳转外链
  LinkOut(link) {
    if (!link || link == "") return false;
    window.open(link, "_blank");
    // window.location.href = link;
  },
  insideWeChatPay(data) {
    return request({
      url: "",
      method: "post",
      token: true,
      data: data,
    });
  },
  outsideWeChatPay(data) {
    return request({
      url: "",
      method: "post",
      token: true,
      data: data,
    });
  },
  //上传图片
  uploadImg(path) {
    let formData = new FormData();
    formData.append("file", path);
    return request({
      url: "/upload/uploadImage",
      method: "post",
      token: true,
      up_type: true,
      data: formData,
    });
  },
  //设置cookie obj例如:[{name:"张三",age:"12"}]
  setCookies(obj, limitTime) {
    let data = new Date(
      new Date().getTime() + limitTime * 24 * 60 * 60 * 1000
    ).toUTCString();

    for (let i in obj) {
      document.cookie = i + "=" + obj[i] + ";expires=" + data;
    }
  },
  //获取cookie cookieName对象名:例如'name'
  getCookie(cookieName) {
    var allcookies = document.cookie;
    //索引长度，开始索引的位置
    var cookie_pos = allcookies.indexOf(cookieName);
    if (cookie_pos != -1) {
      let re = new RegExp("s?" + cookieName + "=([^;]+)(;|$)");
      return document.cookie.match(re)[1];
    } else {
      return null;
    }
  },
  //删除cookie cookieList删除cookie的数组:["name","age"]
  removeCookies(cookieList) {
    let data = new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000
    ).toUTCString();
    for (let i in cookieList) {
      document.cookie = cookieList[i] + "= ;" + "expires=" + data;
    }
  },
  //读取信息
  getStorage(key) {
    let val = localStorage.getItem(key);
    if (!val) {
      return val;
    }
    try {
      val = JSON.parse(val);
    } catch (error) {
      return val;
    }
    return val;
  },
  //存储信息
  setStorage(key, data) {
    if (typeof data == "object") data = JSON.stringify(data);
    localStorage.setItem(key, data);
  },
  //移除已存储的信息
  removeStorage(key) {
    key ? window.localStorage.removeItem(key) : window.localStorage.clear();
  },
  goBack(t, page = -1) {
    t.$router.go(page);
  },
  //刷新页面
  reload() {
    if (window.location.href.indexOf("#reloaded") == -1) {
      window.location.href = window.location.href + "#reloaded";
      window.location.reload();
    }
  },
};
