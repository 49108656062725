import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;
//引入路由
import router from "@/router";
//引入仓库
import store from "./store";

//引入swiper样式
import "swiper/css/swiper.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import VueQuillEditor from 'vue-quill-editor'
 
// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
Vue.use(VueQuillEditor, /* { 默认全局 } */)
//引入vant
import Vant from "vant";
import "@vant/touch-emulator";
import "vant/lib/index.css";
Vue.use(Vant);

// 设置修改页面标题的自定义指令
const title = Vue.directive("title", {
  inserted: function (el) {
    document.title = el.getAttribute("title");
  },
});

//全局组件

//全局方法
import utils from "@/utils/index.js";
//封装请求方法
import request from "@/api/request.js";
//引入登录
import loginDialog from "@/components/login.js";

Vue.prototype.$loginDialog = loginDialog;
Vue.prototype.$axios = request;
Vue.prototype.$util = utils;
Vue.prototype.Link = utils.Link;
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
