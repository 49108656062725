<template>
    <div>
        <div class="model-mask" v-show="showL">
            <div class="login-container">
                <div class="close" @click="close">
                    <img src="@/assets/img/close.png" alt="">
                </div>
                <div class="wrap">
                    <div class="tabs">
                        <div class="tab-item active">
                            手机验证码登录
                        </div>
                        <!-- <div class="tab-item">
                            账号密码登录
                        </div> -->
                    </div>
                    <div class="ipt-box">
                        <div class="form-item">
                            <div class="input">
                                <span class="iconfont icon-shouji"></span>
                                <input type="tel" placeholder="请输入手机号" @blur="iptBlurP()" :class="msgPhone==''?'':'err'"
                                    v-model="ruleForm.phone">
                            </div>
                            <div class="item-err">{{msgPhone}}</div>
                        </div>
                        <div class="phone-code">
                            <div class="form-item">
                                <div class="input">
                                    <span class="iconfont icon-yanzhengma"></span>
                                    <input type="text" placeholder="请输入验证码" @blur="iptBlurC()"
                                        :class="msgCode==''?'':'err'" v-model="ruleForm.code">
                                </div>
                                <div class="item-err">{{msgCode}}</div>
                            </div>
                            <div class="btn" @click="getCode">
                                <span>{{showText ? '获取验证码' : (second + '秒后重发')}}</span>
                            </div>
                        </div>
                        <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                            <el-form-item prop="phone">
                                <el-input placeholder="请输入手机号" v-model="ruleForm.phone">
                                    <i slot="prefix" class="iconfont icon-shouji"></i>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <div class="phone-code">
                                    <el-input placeholder="请输入验证码" v-model="ruleForm.code">
                                        <i slot="prefix" class="iconfont icon-yanzhengma"></i>
                                    </el-input>
                                    <el-button class="btn" @click="getCode">{{showText ? '获取验证码' : (second + '秒后重发')}}
                                    </el-button>
                                </div>
                            </el-form-item>
                        </el-form> -->
                    </div>
                    <div class="loginBtn" @click="Login('ruleForm')">
                        登录
                    </div>
                    <div class="go_register" style="text-align: center;" v-if="false">
                        没有账号？<span>马上注册</span>
                    </div>
                </div>
                <div class="wrap register" v-if="false">
                    <div class="tabs">
                        <div class="tab-item active">
                            马上注册
                        </div>
                        <!-- <div class="tab-item">
                            账号密码登录
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getLoginByPhoneCode,
        getSMS,
        getUserInfo
    } from '@/api/index.js';
    export default {
        name: "login",
        data() {
            return {
                show: true,
                second: 60,
                showText: true,
                showL: false,
                ruleForm: {
                    phone: '',
                    code: '',
                },
                rules: {
                    phone: [{
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    }],
                    code: [{
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur'
                    }],
                },
                msgPhone: '',
                msgCode: '',
            }
        },
        methods: {
            close() {
                this.showL = false;
            },
            open() {
                this.showL = true;
            },
            Login() {
                if (this.ruleForm.phone == '') {
                    this.msgPhone = '请输入手机号码'
                    return false;
                }
                if (this.ruleForm.code == '') {
                    this.msgCode = '请输入验证码'
                    return false;
                }
                if (this.ruleForm.phone != '' && this.ruleForm.code != '') {
                    getLoginByPhoneCode(this.ruleForm).then((res) => {
                        console.log(res);
                        this.$util.setStorage('user-info_', res);
                        getUserInfo().then(r => {
                            this.$util.setStorage('user-info', r);
                            window.location.reload();
                        })
                        // this.close();
                        // this.$util.reload();
                    }).catch(err => {
                        console.log(err, 'err')
                    })
                }
            },
            getCode() {
                if (this.showText) {
                    if (!this.ruleForm.phone || this.ruleForm.phone == '') return false;
                    getSMS({
                        phone: this.ruleForm.phone
                    }).then(res => {
                        this.countDown();
                    })
                }
            },
            countDown() { //倒计时
                this.showText = false
                var interval = setInterval(() => {
                    let times = --this.second
                    this.second = times < 10 ? '0' + times : times //小于10秒补 0
                }, 1000)
                setTimeout(() => {
                    clearInterval(interval)
                    this.second = 60
                    this.showText = true
                }, 60000)
            },
            iptBlurP() {
                if (this.ruleForm.phone == '') {
                    this.msgPhone = '请输入手机号码'
                } else {
                    this.msgPhone = ''
                }
            },
            iptBlurC() {
                if (this.ruleForm.code == '') {
                    this.msgCode = '请输入验证码'
                } else {
                    this.msgCode = ''
                }
            },
        },
        mounted() {

        },
    };
</script>

<style lang="less" scoped>
    .model-mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        background-color: rgba(0, 0, 0, .65);
    }

    .login-container {
        width: 100%;
        // height: 450px;
        height: 370px;
        width: 370px;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgb(0 0 0 / 15%);

        .close {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            padding-top: 12px;
            padding-left: 12px;
            cursor: pointer;
            z-index: 9;

            img {
                width: 16px;
                height: 16px;

            }
        }

        .wrap {
            position: relative;
            padding: 60px 25px 0;

            .tabs {
                display: flex;
                align-items: center;

                .tab-item {
                    width: 100%;
                    text-align: center;
                    color: #999;
                    position: relative;
                    padding-bottom: 20px;
                    // border-bottom: 1px solid #eee;
                    cursor: pointer;
                }

                .active {
                    color: #333;
                    font-weight: bold;

                    &::after {
                        content: '';
                        width: 60px;
                        height: 2px;
                        background-color: #333;
                        position: absolute;
                        left: 50%;
                        margin-left: -30px;
                        bottom: 0;
                    }

                    // &::before {
                    //     content: "";
                    //     position: absolute;
                    //     right: 0;
                    //     width: 1px;
                    //     height: 20px;
                    //     background-color: #aaa;
                    // }
                }
            }

            .ipt-box {
                margin-top: 30px;

                .phone-code {
                    margin-top: 10px;
                    display: flex;

                    .btn {
                        margin-left: 10px;
                        width: 120px;
                        height: 40px;
                        line-height: 14px;
                        display: inline-block;
                        background: #f1f5f8;
                        cursor: pointer;
                        border: 1px solid #DCDFE6;
                        color: #606266;
                        -webkit-appearance: none;
                        text-align: center;
                        box-sizing: border-box;
                        outline: 0;
                        // margin: 0;
                        transition: .1s;
                        font-weight: 500;
                        padding: 12px 20px;
                        font-size: 14px;
                        border-radius: 4px;
                        white-space: nowrap;

                        &:hover {
                            color: #333;
                            background-color: #f1f5f8;
                            border-color: #DCDFE6;
                        }
                    }
                }

                .form-item {
                    line-height: 40px;
                    position: relative;
                    font-size: 14px;
                    margin-bottom: 22px;

                    .input {
                        position: relative;
                        width: 100%;
                        display: block;

                        padding: 0;

                        // padding-right: 15px;
                        height: 40px;

                        .iconfont {
                            font-size: 20px;
                            color: #C0C4CC;
                            position: absolute;
                            left: 5px;
                        }

                        input {
                            width: 100%;
                            border: 1px solid #DCDFE6;
                            height: 40px;
                            padding-right: 15px;
                            padding-left: 40px;
                            border-radius: 4px;

                        }

                        input::placeholder {
                            color: #C0C4CC;
                        }

                        input.err {
                            border-color: #F56C6C;
                        }
                    }

                    .item-err {
                        color: #F56C6C;
                        font-size: 12px;
                        line-height: 1;
                        padding-top: 4px;
                        position: absolute;
                    }
                }
            }

            .loginBtn {
                width: 100%;
                background: #171717;
                text-align: center;
                color: #fff;
                font-size: 14px;
                border-radius: 8px;
                margin-top: 36px;
                cursor: pointer;
                height: 48px;
                line-height: 48px;
            }

            .go_register {
                font-size: 13px;
                text-align: center;
                margin-top: 10px;

                span {
                    color: rgba(203, 0, 24, 0.8);
                    cursor: pointer;
                }
            }
        }
    }
</style>