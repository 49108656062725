<template>
    <div>
        <div id="right-menu">
            <!-- <div class="menu-item">
                <div class="iconfont icon-gonggao"></div>
                <span>公告</span>href="tel:13828172679"
            </div> -->
            <a :href="'tel:'+info.store_service_telephone" class="menu-item">
                <div class="iconfont icon-kefu bold"></div>
                <span>客服</span>
            </a>
            <div class="menu-item">
                <div class="iconfont icon-xiaoxi bold"></div>
                <span>加微信</span>
                <span>送惊喜</span>
                <div class="left-box">
                    <div class="con wx">
                        <img :src="info.store_wechat_qrcode" alt="">
                        <p>二维码</p>
                    </div>
                </div>
            </div>
            <div class="menu-item">
                <div class="iconfont icon-yuancheng01-copy bold"></div>
                <span>远程</span>
                <span>服务</span>
                <div class="left-box">
                    <div class="con wx">
                        <img :src="info.store_remote_service" alt="">
                        <p>二维码</p>
                    </div>
                </div>
            </div>
            <!-- <div class="menu-item">
                <div class="iconfont icon-help"></div>
                <span>帮助</span>
            </div> -->
            <div class="menu-item" @click="Link('member',{},true)">
                <span class="iconfont icon-wode"></span>
                <span>个人</span>
                <span>中心</span>
            </div>
            <!-- <router-link class="menu-item" to="/member">
                <span class="iconfont icon-wode"></span>
                <span>个人</span>
                <span>中心</span>
            </router-link> -->

            <div class="menu-item" @click="backTop" v-show="showBtn">
                <span class="iconfont icon-huidaodingbu"></span>
                <span>顶部</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getSysConfig
    } from '@/api/index.js'
    export default {
        name: "rightMenu",
        data() {
            return {
                showBtn: false,
                isBacking: false,
                info:{}
            }
        },
        methods: {
            showbtn() {
                let that = this;
                let scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                that.scrollTop = scrollTop;
                if (scrollTop > 900) {
                    this.showBtn = true;
                } else {
                    this.showBtn = false;
                }
            },
            backTop() {
                if (this.isBacking) return false;
                this.isBacking = true;
                let that = this;
                var timer = setInterval(function () {
                    let osTop =
                        document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 10);
                    document.documentElement.scrollTop = document.body.scrollTop =
                        osTop + ispeed;
                    this.isTop = true;
                    if (osTop === 0) {
                        clearInterval(timer);
                        that.isBacking = false;
                    }
                }, 30);
            },
            getConfig(){
                getSysConfig().then(r=>{
                    this.info = r;
                })
            }
        },
        mounted() {
            window.addEventListener("scroll", this.showbtn, true);
            this.getConfig();
        },
    };
</script>

<style lang="less" scoped>
    .left-box {
        display: flex;
        align-items: center;
        width: 180px;
        padding-right: 10px;
        .con {
            flex: 1;
            background-color: #fff;
            height: 110px;
            border-radius: 5px;
            box-shadow: 2px 2px 4px #aaa;
            height: 200px;
        }
        .wx{
            padding: 20px;
            text-align: center;
            img{
                max-width: 100%;
                width: auto;
                display: block;
            }
        }

        .tips {
            // width: 0;
            // height: 0;
            // border-left: 16px solid #fff;
            // border-top: 12px solid transparent;
            // border-bottom: 12px solid transparent;
            width: 10px;
        }

    }
</style>