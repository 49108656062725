<template>
    <div>
        <div class="footer-wrap">
            <div class="footer-content">
                <div class="l-quick-link">
                    <ul>
                        <li>栏目导航</li>
                        <li v-for="item in menu" :key="item.id">
                             <router-link :to="{name:'list',query:{classcode:item.classcode}}">{{item.name}}</router-link>
                        </li>
                    </ul>
                    <!-- <ul>
                        <li>素材下载</li>
                        <li>
                            <a href="">年会员模型</a>
                        </li>
                        <li>
                            <a href="">原创模型</a>
                        </li>
                        <li>
                            <a href="">免费模型</a>
                        </li>
                        <li>
                            <a href="">PS素材</a>
                        </li>
                        <li>
                            <a href="">SU模型</a>
                        </li>
                    </ul>
                    <ul>
                        <li>北纬VIP</li>
                        <li>
                            <a href="">开通VIP</a>
                        </li>
                        <li>
                            <a href="">VIP特权</a>
                        </li>
                    </ul>
                    <ul>
                        <li>帮助中心</li>
                        <li>
                            <a href="">用户协议</a>
                        </li>
                        <li>
                            <a href="">审核标准</a>
                        </li>
                        <li>
                            <a href="">常见问题</a>
                        </li>
                        <li>
                            <a href="">意见反馈</a>
                        </li>
                        <li>
                            <a href="">关于我们</a>
                        </li>
                    </ul> -->
                    <ul class="mzsm">
                        <li>免责声明</li>
                        <li>
                            {{info.store_disclaimer}}
                        </li>
                    </ul>
                    <ul>
                        <li>网站备案</li>
                        <li v-html="info.store_website_filing_number"></li>
                    </ul>
                </div>
                <ul class="c-qrcode">
                    <li>
                        <div class="img">
                            <img :src="info.store_public_qrcode" alt="">
                        </div>
                        公众号
                    </li>
                    <li>
                        <div class="img">
                            <img :src="info.store_h5_qrcode" alt="">
                        </div>
                        手机端
                    </li>
                </ul>
                <div class="kf-img">
                    <img src="@/assets/img/kefu2.png" alt="">
                </div>
                <ul class="r-contact">
                    <li>客服热线:（周一至周六，8:30-21:00）</li>
                    <li>
                        <b>{{info.store_service_telephone}}</b>
                    </li>
                    <!-- <li>
                        <a href="">
                            <div class="img">
                                <img src="@/assets/img/qq.png" alt="">
                            </div>
                            QQ在线客服
                        </a>
                    </li> -->
                </ul>
            </div>
            <div class="jszc w1703">
                技术支持：<a target="_bank" href="http://www.hk365.top">象牙网络</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getMainCategoryList,
        getSysConfig
    } from '@/api/index.js'
    export default {
        name: "footers",
        data() {
            return {
                menu:[],
                info:{}
            }
        },
        methods:{
            getMenu(){
                getMainCategoryList().then(r=>{
                    this.menu = r;
                })
            },
            getConfig(){
                getSysConfig().then(r=>{
                    this.info = r;
                })
            }
        },
        mounted(){
            let menu = this.$util.getStorage('menu');
            if(menu){
                this.menu = menu;
            }else{

            }
            this.getConfig();
        }
    };
</script>

<style lang="less" scoped>
    .jszc{
        // width: 100%;
        text-align: center;
        padding-bottom: 20px;
        color: #fff;
    }
</style>